// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$mkg-blue-palette: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: #2196f3,
  600: #1e88e5,
  700: #1976d2,
  800: #1565c0,
  900: #0d47a1,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white,
  ),
);

$mkg-orange-palette: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #f44336,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    500: white, // rgba(black, 0.87),
    600: white, // rgba(black, 0.87),
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white),
);

$mkg-primary: mat.define-palette($mkg-blue-palette, 700);

$mkg-accent: mat.define-palette($mkg-orange-palette, 500);

// The "warn" palette is optional and defaults to red if not specified.
// $my-warn: mat.define-palette(mat.$red-palette);

$mkg-typography: mat.define-typography-config(
  $font-family: 'Roboto',
  $headline-1: mat.define-typography-level(7rem, 7rem, 300, $letter-spacing: -0.05em),
  $headline-2: mat.define-typography-level(3.5rem, 3.5rem, 400, $letter-spacing: -0.02em),
  $headline-3: mat.define-typography-level(3rem, 3rem, 400, $letter-spacing: -0.005em),
  $headline-4: mat.define-typography-level(2rem, 2rem, 400),
  $headline-5: mat.define-typography-level(1.5rem, 1.5rem, 400),
  $headline-6: mat.define-typography-level(1.25rem, 1.25rem, 400),
  $subtitle-1: mat.define-typography-level(1rem, 1rem, 400),
  $subtitle-2: mat.define-typography-level(0.825rem, 0.875rem, 500),
  $body-1: mat.define-typography-level(0.8rem, 0.875rem, 400),
  $body-2: mat.define-typography-level(0.75rem, 0.75rem, 400),
  $caption: mat.define-typography-level(0.75rem, 0.75rem, 400),
  $button: mat.define-typography-level(0.875rem, 14rem, 400)
);

// $font-family: $font-family-medium,
// $font-family: $font-family-medium,
$custom-typography-config: mat.define-legacy-typography-config(
  $font-family: '"Roboto", "Open Sans", "Helvetica Neue", sans-serif',
  $headline: mat.define-typography-level(22px, 22px, 600), /*<h1>*/
  $title: mat.define-typography-level(18px, 18px, 600),/*<h2>*/
  $subheading-2: mat.define-typography-level(
  $font-weight: 400,
  $font-size: 18px,
  $line-height: 1,
  $letter-spacing: normal), /*<h3>*/
  $subheading-1: mat.define-typography-level(
  $font-weight: 400,
  $font-size: 14px,
  $line-height: 1,
  $letter-spacing: normal), /*<h4>*/
  $display-4: mat.define-typography-level(18px, 18px, 300, $letter-spacing: -0.05em), /*No Native Element*/
  $display-3: mat.define-typography-level(14px, 14px, 400, $letter-spacing: -0.02em), /*No Native Element*/
  $body-1: mat.define-typography-level(14px, 30px, 400), /* Body Text */
  $caption: mat.define-typography-level(14px, 30px, 500),/*No Native Element*/
  $button: mat.define-typography-level(14px, 30px, 500),
  $display-2: mat.define-typography-level(12px, 12px, 400, $letter-spacing: -0.005em), /*No Native Element*/
  $display-1: mat.define-typography-level(14px, 14px, 400), /*No Native Element*/
  $body-2: mat.define-typography-level(12px, 25px, 400), /*No Native Element*/
  $input: mat.define-typography-level(14px, 20px, 500),
);

// $custom-typography-config2: mat.define-typography-config(
//   $font-family: '"Robooto", "Open Sans", "Helvetica Neue", sans-serif',
//   $headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
//   $headline-2: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
//   $headline-3: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
//   $headline-4: mat.define-typography-level(34px, 40px, 400),
//   $headline-5: mat.define-typography-level(24px, 32px, 400),
//   $headline-6: mat.define-typography-level(20px, 32px, 400),
//   $subtitle-1: mat.define-typography-level(16px, 20px, 400),
//   $body-1: mat.define-typography-level(14px, 20px, 400),
//   $body-2: mat.define-typography-level(12px, 14px, 400),
//   $subtitle-2: mat.define-typography-level(16px, 28px, 400),
//   $caption: mat.define-typography-level(12px, 20px, 400),
//   $button: mat.define-typography-level(14px, 14px, 500),
// );


// Create the theme object (a Sass map containing all of the palettes).
$mekanic-go-theme: mat.define-light-theme((
  color: (
    primary: $mkg-primary,
    accent: $mkg-accent,
  ),
  typography: $mkg-typography,
  density: 0,
));

:root {
  --background-color: white;
  --primary-color: #1976d2;
  --accent-color: #f44336;
  --scrollbar-color: #1976d2;
  --scrollbar-background-color: #d1d1d1;
  --menu-background-color: white;
  --menu-selected-color: #1976d2;
  --menu-letter-color: black;
  --letter-color: black;
  --header-color: #1976d2;
  --footer-color: #1976d2;
}


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($mekanic-go-theme);


/**
 *  Workaround to set elevation on mat-card
 *  @see [this issue](https://github.com/angular/components/issues/26094)
 */
 @for $i from 0 through 24 {
  .mat-mdc-card.mat-elevation-z#{$i} {
    @include mat.elevation($i);
  }
}
